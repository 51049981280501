<template>
  <div class="container">
    <PrimePreloader v-if="loading"/>
    <div v-else class="my-4">
      <h4 class="text-center my-3">Организации</h4>

      <DataTable :value="organizations" showGridlines stripedRows
                 :globalFilterFields="['name']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">

        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск"/>
            </span>
          </div>
        </template>
        <Column field="name" header="Название"></Column>
        <Column field="type" header="Тип"></Column>

        <Column header="Лидер">
          <template #body="{data}">
            {{ data.leader_id.last_name }} {{ data.leader_id.first_name }} - GPA {{ data.leader_id.gpa }}
          </template>
        </Column>

        <Column field="profile" header="Профиль"></Column>

        <Column header="Вступление">
          <template #body="{data}">
            <div v-if="organizationMembersJoin?.find(i=>i.organization_id == data.id)">
              {{organizationMembersJoin?.find(i=>i.organization_id == data.id)?.status == 0 ? 'В ожидании' : organizationMembersJoin?.find(i=>i.organization_id == data.id)?.status == 1 ? 'Принято' : 'Отказано'}}
            </div>
            <div v-else>
              <Button label="Вступить" @click="joinOrganization(data.id)"/>
            </div>

          </template>
        </Column>

      </DataTable>

      <ConfirmDialog group="joinOrganization"></ConfirmDialog>

    </div>
  </div>
</template>


<script>
import {FilterMatchMode, FilterOperator} from "primevue/api";
import {mapActions, mapState} from 'vuex'
export default {
  name: "OrganizationJoining",
  data() {
    return {
      loading: true,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      },
    }
  },
  computed: {
    ...mapState('organization', ['organizations', 'organizationMembersJoin']),
  },
  methods: {
    ...mapActions('organization', ['GET_ORGANIZATIONS', 'GET_ORGANIZATION_MEMBERS_JOIN', 'POST_ORGANIZATION_MEMBERS_JOIN']),


    joinOrganization(organization_id) {

      this.$confirm.require({
        group: 'joinOrganization',
        message: `Вы точно хотите подать заявку?`,
        header: 'Подача заявки',
        icon: 'pi pi-info-circle',
        accept: async () => {
          let form = {}
          form['organization_id'] = organization_id

          console.log(form, 'form')

          const postRes = await this.POST_ORGANIZATION_MEMBERS_JOIN(form)
          if (postRes) {
            await this.GET_ORGANIZATIONS()
            await this.GET_ORGANIZATION_MEMBERS_JOIN()
            this.$message({title: 'Успешно подано'})
          }
        }
      })
    },
    //   async joinOrganization(organization_id) {
    //     let form = {}
    //     form['organization_id'] = organization_id
    //
    //     console.log(form, 'form')
    //
    //     const postRes = await this.POST_ORGANIZATION_MEMBERS_JOIN(form)
    //     if (postRes) {
    //       await this.GET_ORGANIZATIONS()
    //       this.$message({title: 'Успешно подано'})
    //     }
    //   }
    // },
  },
  async mounted() {
    await this.GET_ORGANIZATIONS()
    await this.GET_ORGANIZATION_MEMBERS_JOIN()

    this.loading = false
  }
}
</script>

<style scoped>

</style>
